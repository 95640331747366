import { BASE_URL } from '@constants/UHEEndpoints';
import {
  ACCESS_TOKEN_KEY,
  HTTP_ERROR_MESSAGES,
} from '@constants/UHESettings';
import {
  HTTPResponseError,
  HTTPResponseWarning,
  ServerError,
} from '@constants/UHEServerExceptions';

export const defaultPostHeaders = [
  {
    name: 'Content-Type',
    value: 'application/json',
  },
  {
    name: 'X-User-Agent',
    value: `Caregility Remote Controll Application; version:${process.env.REACT_APP_VERSION}`,
  },
];

const RestManager = {
  formDataRequest: async (url, data, headers = [], isEssential = true) => {
    const options = {
      method: 'POST',
      headers: RestManager.getHeaders(headers),
      body: data,
    };
    const res = await fetch(BASE_URL + url, options).then(async (response) => {
      const hasError = RestManager.checkStatus(response, isEssential);
      const serverResponse = await RestManager.tryResponseJSON(response);
      if (hasError) {
        RestManager.handleErrorResponse(serverResponse, isEssential);
      }
      return serverResponse;
    });

    return res;
  },

  request: (url, method = 'GET', headers = [], isEssential = true, baseUrl = BASE_URL) => {
    const options = {
      method,
      headers: RestManager.getHeaders([...defaultPostHeaders, ...headers]),
      mode: 'cors',
      cache: 'default',
    };
    const json = fetch(baseUrl + url, options).then((response) => {
      RestManager.checkResponseStatus(response, isEssential);
      return RestManager.tryResponseJSON(response);
    });

    return json;
  },

  requestFile: async (url, method = 'GET', headers = [], isEssential = true) => {
    const options = {
      method,
      headers: RestManager.getHeaders(headers),
      mode: 'cors',
      cache: 'default',
    };
    const json = await fetch(BASE_URL + url, options).then((response) => {
      RestManager.checkResponseStatus(response, isEssential);
      return response.blob();
    });

    return json;
  },

  /**
   * @description Handles Request && Response for CSV Export
   * @param {Object} url
   * @param {string} method
   * @param {Object} headers
   * @param {boolean} isEssential
   * @returns {Object}
   */
  exportCsvFile: async (url, method = 'GET', headers = [], isEssential = true) => {
    const options = {
      method,
      headers: RestManager.getHeaders(headers),
      cache: 'no-cache',
    };
    const json = await fetch(url, options).then(async (response) => {
      RestManager.checkResponseStatus(response, isEssential);
      const data = await response.blob();
      const a = document.createElement('a');
      const csvUrl = window.URL.createObjectURL(data);
      const fileName = response.headers.get('content-disposition').split('filename*=')[1].split("UTF-8''").pop();

      a.href = csvUrl;
      a.download = fileName;
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(csvUrl);
    });

    return json;
  },

  tryResponseJSON: async (response) => {
    try {
      return await response.json();
    } catch (e) {
      console.log('tryResponseJSON: ', e);
      return {};
    }
  },

  requestWithoutQueryParams: async (
    url,
    method,
    bodyData = null,
    headers = [],
    isEssential = true,
    isAuthBearer = true,
    baseUrl = BASE_URL,
  ) => {
    const options = {
      method,
      headers: RestManager.getHeaders(
        [...defaultPostHeaders, ...headers],
        isAuthBearer,
      ),
      mode: 'cors',
      cache: 'default',
    };

    if (bodyData) {
      options.body = JSON.stringify(bodyData);
    }

    const finalUrl = baseUrl + url;
    const json = fetch(finalUrl, options).then(async (response) => {
      if (method === 'DELETE') {
        return { status: response.status };
      }
      RestManager.checkResponseStatus(response, isEssential);
      return RestManager.tryResponseJSON(response);
    }).catch((error) => console.log(error));
    return json;
  },

  getAuthToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },

  /**
   * @description Creates Headers Object
   * @param {Object} additionalHeaders
   * @param {boolean} isAuthBearer
   * @returns {Object}
   */
  getHeaders(additionalHeaders, isAuthBearer = true) {
    const headers = new Headers();
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);

    if (isAuthBearer) {
      headers.append('Authorization', `Bearer ${accessToken}`);
    }

    additionalHeaders.forEach((item) => {
      headers.append(item.name, item.value);
    });

    return headers;
  },

  getToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },

  /**
   * @description Checks Server Response Status Codes && Redirects || Handling Errors Depending on the Status Code
   * @param {Object} response
   * @param {boolean} isEssential
   * @returns {boolean|void}
   */
  checkResponseStatus(response, isEssential = true) {
    if (response.status === 401) {
      window.location.href = '/session-expired';
    }
    if (response.status < 200 || response.status > 204) {
      RestManager.handleErrorResponse(response, isEssential);
    }
  },

  /**
   * @description Checks Server Response Status Codes && Redirects Depending on the Status Code
   * @param {Object} response
   * @param {boolean} isEssential
   * @returns {boolean|void}
   */
  checkStatus(response, isEssential = true) {
    if (
      response.status === 401
      && window.location.pathname.indexOf('/activate-user') === -1
    ) {
      window.location.href = window.env_overrides.REACT_APP_SSO_REDIRECT_URL
        || process.env.REACT_APP_SSO_REDIRECT_URL;
    } else if (response.status < 200 || response.status > 204) {
      return true;
    }
    return false;
  },

  /**
   * @description Handle Errors that are Coming as Response from the Server
   * @param {Object} response
   * @param {boolean} isEssential
   * @returns {void}
   */
  handleErrorResponse(response, isEssential) {
    let error;

    switch (response.status) {
      case 400:
        if (isEssential && response.message === 'Missing token') {
          error = new HTTPResponseError('missing_token', response.status);
        } else if (isEssential && response.message === 'Invalid token') {
          error = new HTTPResponseError('invalid_token', response.status);
        } else if (isEssential && response.message === 'Password too short') {
          error = new HTTPResponseError('short_password', response.status);
        } else if (isEssential && response.message === 'Password too simple') {
          error = new HTTPResponseError('simple_password', response.status);
        } else if (isEssential) {
          error = new HTTPResponseError(
            response.message,
            response.status,
          );
        } else {
          error = new HTTPResponseWarning(
            response.message,
            response.status,
          );
        }
        break;
      case 403:
      case 404:
      case 409:
        if (isEssential) {
          error = new HTTPResponseError(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        } else {
          error = new HTTPResponseWarning(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        }
        break;
      case 500:
      case 502:
      case 503:
      case 504:
        error = new ServerError(HTTP_ERROR_MESSAGES[response.status]);
        break;
      default:
        error = new HTTPResponseError(response.message, response.status);
    }

    throw error;
  },
};

export default RestManager;
